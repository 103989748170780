import React, {useState, useEffect} from 'react'
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Home from '../../Pages/Home'
import Instructor from '../../Pages/Instructor'
import Classes from '../../Pages/Classes'
import Contact from '../../Pages/Contact'

const Navigation= () => { 
    const [page, setPage] = useState("0");

    useEffect(() => {
        var currentTab = window.sessionStorage.getItem("page")
        if(currentTab){
            setPage(currentTab)
        }
    }, [page])
    
    const handleChange = (event, page) => {
        window.sessionStorage.setItem("page", page);
        setPage(page);
      };

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static">
            <TabContext value={page}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChange} 
                            variant="scrollable" 
                            scrollButtons 
                            allowScrollButtonsMobile 
                            aria-label="scrollable force tabs example">
                        <Tab label="Home" value="0"/>
                        <Tab label="Classes" value="1"/>
                        <Tab label="Instructors" value="2"/>
                        <Tab label="Contact" value="3"/>
                    </TabList>
                </Box>
                <TabPanel value="0">
                    <Home />
                </TabPanel>
                <TabPanel value="1">
                    <Classes handleChange={handleChange}/>
                </TabPanel>
                <TabPanel value="2">
                    <Instructor />
                </TabPanel>
                <TabPanel value="3">
                    <Contact />
                </TabPanel>
            </TabContext>
            </AppBar>
        </Box>
    )
}

export default Navigation
