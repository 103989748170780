import React from 'react'
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import PalmettoTwirlersIcon from '../../Assets/twirling-logo-transparent.svg'
import './styles.css'; 
import { Typography } from '@mui/material';

const Navbar = () => {
    return (
        <Box sx={{ flexGrow: 1, border: 0 }}>
            <AppBar sx={{ justifyContent: 'center'}} position="static">
                <Toolbar>
                    <Typography variant='h6' sx={{ fontStyle: 'italic', fontWeight: 'light'}}>PALMETTO TWIRLERS</Typography>
                    <Box sx={{ flexGrow: 1 }} />
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu">
                        <img style={{width: "1em"}} src={PalmettoTwirlersIcon} alt="homeIcon"/>
                    </IconButton>
                </Toolbar>
            </AppBar>
        </Box>
    )
}

export default Navbar
