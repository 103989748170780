import { Typography, Button, Box} from '@mui/material';
import React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Backdrop from '@mui/material/Backdrop';
import './styles.css';

const Classes = (props) => {
    const [openGroup, setOpenGroup] = React.useState(false);
    const handleOpenGroup = () => setOpenGroup(true);
    const handleCloseGroup = () => setOpenGroup(false);
    const handleRegisterClick = (url) => {
        window.open(url);
    }
    const style = {
        display: 'flex',
        flexDirection: 'column',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 300,
        maxHeight: '60%',
        overflow: "scroll",
        ['@media (min-width:1025px)']: { // eslint-disable-line no-useless-computed-key
            width: 500
        },
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 2,
        color: '#1c3144'
      };

    return (
        <div className="Page">
            <Box className="Classes">
                <Typography variant="h5" className="Heading"><b>Open Classes</b></Typography>
                <Typography variant="h6" className="Heading">Looking to polish up those twirling skills? Take a look at some of the available classes.</Typography>
                <Box className="Offerings">
                <Card className="Card">
                <CardHeader title="Mount Pleasant Classes" className="CardHeader"/>
                    <CardContent>
                        <Typography variant="body1" className="CardText">
                            <b>Date:</b> Septermber 9th - December 9th, 2024
                            <br />
                            <b>Ages 4-8:</b> Mondays at 6:00pm
                            <br />
                            <b>Ages 9-13:</b> Mondays at 6:45pm
                            <br />
                            <b>Location:</b> RL Jones Recreation Center
                        </Typography>
                    </CardContent>
                    <CardActions sx={{ padding: '16px' }}>
                        <Button variant="outlined" size="medium" onClick={handleOpenGroup}>Description</Button>
                        <Button variant="outlined" size="medium" onClick={() => handleRegisterClick("https://scmtpleasantweb.myvscloud.com/webtrac/web/search.html?Action=Start&SubAction=&_csrf_token=kd6P6Z0Y6D722J4B2G372R5X015T4S4S0A724H563Z1I704N004X085U60594T716Z606C6C6A704O6M5G035W4V6R6A126R5Z594V1F5X635B6H0L6T645H6G18595T5E&keywordoption=Match+One&keyword=&type=Adult&type=Aquatic+Exercise&type=Aquatics&type=Camp&type=FUNdamentals&type=Senior&type=Special+Events&type=Sponsorships&type=Swim+Lessons&type=Swim+Team&type=Tennis&type=Unified&type=Youth+Athletics&type=Youth+Programs&category=&primarycode=514dan007&registrationevent=&instructor=&daysofweek=&dayoption=All&timeblock=&spotsavailable=&bydayonly=No&beginyear=&season=&gender=&showwithavailable=No&display=Detail&module=AR&multiselectlist_value=&arwebsearch_buttonsearch=yes")}>Register</Button>
                    </CardActions>
                </Card>
                <Card className="Card">
                    <CardHeader title="Camps" className="CardHeader"/>
                    <CardContent>
                        <Typography variant="body1" className="CardText">
                            Check back soon for new camps and clinics! Have a suggestion?
                        </Typography>
                    </CardContent>
                    <CardActions sx={{ padding: '16px' }}>
                        <Button variant="outlined" sx={{ color: '#1c3144'}} size="medium" onClick={(event) => props.handleChange(event, '3')}>Contact</Button>
                    </CardActions>
                </Card>
                <Card className="Card">
                <CardHeader title="Private Lessons" className="CardHeader"/>
                    <CardContent>
                        <Typography variant="body1" className="CardText">
                            Private lessons are available for routine choreography and skill development.
                        </Typography>
                    </CardContent>
                    <CardActions sx={{ padding: '16px' }}>
                        <Button variant="outlined" sx={{ color: '#1c3144' }}  size="medium" onClick={(event) => props.handleChange(event, '3')}>Contact</Button>
                    </CardActions>
                </Card>
                </Box>
            </Box>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={openGroup}
                className="Modal"
                onClose={handleCloseGroup}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                timeout: 500,
                }}>
                <Fade in={openGroup}>
                    <Box sx={style}>
                        <Typography id="transition-modal-title" variant="h6" component="h2" sx={{ alignSelf: "center"}}>
                            Group Classes
                        </Typography>
                        <Typography variant="body1" className="CardText" sx={{ m: 2 }}>
                            Learn to twirl baton! Baton twirling is an excellent way to develop hand-eye coordination, rhythm, gracefulness, self-discipline, and overall body coordination. Students will learn beginner baton twirling skills and a routine to perform for their family and friends at the end of the session. They will also perform at various community events. Open to experienced or first time twirlers.
                        </Typography>
                        <Box className="DescriptionContainer">
                            <Button variant="outlined" size="medium" className="Description" onClick={handleCloseGroup}>Close</Button>
                        </Box>
                        
                    </Box>
                </Fade>
            </Modal>
        </div>
    )
}

export default Classes