import { Typography, Box} from '@mui/material'
import React from 'react'
import './styles.css'
import LocationCityIcon from '@mui/icons-material/LocationCity';
import SchoolRoundedIcon from '@mui/icons-material/SchoolRounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';

const Home = () => {


    return (
        <div className="Home">
            <Box className="HomeIcons">
                <Box className="IconContainer">
                    <PersonRoundedIcon className="Icon" />
                    <Typography>Owner - Carson King </Typography>
                </Box>
                <Box className="IconContainer">
                    <LocationCityIcon className="Icon" />
                    <Typography>Charleston, SC</Typography>
                </Box>
                <Box className="IconContainer">
                    <SchoolRoundedIcon className="Icon" />
                    <Typography>Group | Private | Camps</Typography>
                </Box>
            </Box>
            <img src='https://s3.amazonaws.com/palmettotwirlers.com/Assets/palmetto-home.svg' className="HomeAsset" alt="Twirling Icon"/>
            <Typography className="HomeContent" variant="body1">Welcome to <b>Palmetto Twirlers</b>! Check out our available classes to find the perfect fit for your child.</Typography>
        </div>
    )
}

export default Home