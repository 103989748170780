import './App.css';
import Navbar from './Components/Navbar';
import Navigation from './Components/Navigation';
import Footer from './Components/Footer'
import * as React from 'react';
import { useEffect, useState, Fragment } from 'react';
import CircularProgress from '@mui/material/CircularProgress';

function App() {
  const [isLoading, setIsLoading] = useState(true);

  const preloadImages = async (images) => {
      const promises = await images.map((i) => {
          return new Promise(function (resolve, reject) {
              const img = new Image();

              img.src = i;
              img.onload = resolve();
              img.onerror = reject();
          });
      });

      await Promise.all(promises);

      setIsLoading(false);

  }

  useEffect(() => {
      const images = [
          'https://s3.amazonaws.com/palmettotwirlers.com/Assets/maps.jpg',
          'https://s3.amazonaws.com/palmettotwirlers.com/Assets/headshot.JPG',
          'https://s3.amazonaws.com/palmettotwirlers.com/Assets/palmetto-home.svg',
          'https://s3.amazonaws.com/palmettotwirlers.com/Assets/amy-headshot2.jpg'
      ];

      preloadImages(images);
  }, []);

  return (
    <Fragment>
      {isLoading
      ?
      <div>
        <CircularProgress />
      </div>
      :
      <Fragment>
        <Navbar />
        <Navigation />
        <Footer />
      </Fragment>
      }
    </Fragment>
  );
}

export default App;
