import { Typography, Box, Button} from '@mui/material'
import React from 'react'
import PhoneIcon from '@mui/icons-material/Phone';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import EmailIcon from '@mui/icons-material/Email';
import './styles.css'

const Contact = () => {
    const sendEmail = () => {
        window.open("mailto:carsonaking5@gmail.com?cc=&subject=Palmetto Twirlers&body=");
    }
    const callPhone = () => {
        window.open("tel:+15713456384");
    }

    const handleLocationClick = () => {
        var mapLocationUrl = 'https://maps.apple.com/?address=391%20Egypt%20Rd,%20Mount%20Pleasant,%20SC%20%2029464,%20United%20States&auid=5097763676518082784&ll=32.846087,-79.855427&lsp=9902&q=R.L.%20Jones%20Center&_ext=CjMKBQgEEOIBCgQIBRADCgUIBhCRAQoECAoQAAoECFIQAwoECFUQDQoECFkQAQoFCKQBEAESJimuvD8qu2tAQDEDExzcF/dTwDkskmWG4WxAQEH3iDSsaPZTwFAD&t=m';
        window.open(encodeURI(mapLocationUrl), '_system', 'location=no');
    }

    return (
        <div className="Contact">
            <Box className="InfoContainer">
                <Typography variant="h5"><b>Get In Touch</b></Typography>
                <Typography variant="h6" className="EmailMe">We would love to hear from you!</Typography>
                <Box className="ContactInfo">
                    <Button variant="outlined" onClick={callPhone} className="ContactMe"><PhoneIcon className="Icon"/>Call Me</Button>
                    <Button variant="outlined" onClick={sendEmail} className="ContactMe"><EmailIcon className="Icon"/>Email Me</Button>
                </Box>
            </Box>
            
            <Box className="MapContainer">
                <Box className="Charleston">
                    <FmdGoodIcon className="Icon" />
                    <Typography variant="body1">Charleston, SC</Typography>
                </Box>
                <img src='https://s3.amazonaws.com/palmettotwirlers.com/Assets/maps.jpg' alt="RL Jones Center" className="Map" onClick={handleLocationClick}/>
            </Box>
            
        </div>
    )
}

export default Contact