import { Typography } from '@mui/material'
import React from 'react'
import './styles.css'

const Instructor = () => {
    return (
        <div>
            <div className="Instructor">
                <img src='https://s3.amazonaws.com/palmettotwirlers.com/Assets/headshot.JPG' alt="Carson King" className="Headshot"/>
                <div className="HeadshotText">
                    <Typography variant="h6" className="Header"><b>Owner/Instructor - Carson King</b></Typography>
                    <Typography variant="body1" className="Content">
                        Carson started twirling when she was six years old and began twirling competitively at the age of nine. She competed in state and national competitions in solo and team until college. In 2015, Carson started twirling for Clemson University, twirling on the majorette line for three years and as the Feature Twirler for her fourth and final season. During her time at Clemson, Carson performed at all the home football games, bowl games, and three National Championships! 
                        In 2019, Carson competed for Miss Clemson University, winning an overall talent award and placing as 1st runner up. That same year, Carson was crowned Miss Greater Easley and competed for the title of Miss South Carolina, performing her baton twirling talent on the Miss South Carolina stage!
                    </Typography>
                    <Typography variant="body1" className="Content">Carson started teaching at a dance and baton studio in Anderson, SC. She has taught baton and dance to students of all ages and levels in group classes and private lessons. Carson has also choreographed for high school majorette lines and as a guest instructor for camps and clinics. </Typography>
                    <Typography variant="body1" className="Content">Carson is excited to share her love of baton twirling with others and bring twirling to the Charleston area!</Typography>
                </div>
            </div>
            <div className="Instructor">
                <img src='https://s3.amazonaws.com/palmettotwirlers.com/Assets/amy-headshot2.jpg' alt="Amy Rees" className="Headshot"/>
                <div className="HeadshotText">
                    <Typography variant="h6" className="Header"><b>Instructor - Amy Rees</b></Typography>
                    <Typography variant="body1" className="Content">
                        Amy is originally from Loganville, Georgia and began twirling at age 9. She competed in state, regional, and national competitions as both an individual competitor and on her team, Premier Dance Twirl Teams. Amy was a Clemson University Tiger Twirler and performed at Clemson football games, basketball games, and various community performances. She even got to travel and perform at the National Championship game in 2019!
                    </Typography>
                    <Typography variant="body1" className="Content">Amy loves teaching twirling fundamentals and setting her students up for success later on!</Typography>
                </div>
            </div>
        </div>
    )
}

export default Instructor